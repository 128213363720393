import React from "react";

import "../css/AtomSpinner.css";


interface AtomSpinnerProps {
  // Define any props here if needed, for example, size or color
  size?: number,
  color?: string,
}

const AtomSpinner: React.FC<AtomSpinnerProps> = ( { size = 60, color = "#333" }: AtomSpinnerProps ) => {
  const spinnerStyle = {
    width: `${size}px`,
    height: `${size}px`
  };

  const dotStyle = {
    backgroundColor: color
  };

  return (
    <div className="atom-spinner" style={spinnerStyle}>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
      <div style={dotStyle}></div>
    </div>
  );
};

export default AtomSpinner;
