

import { useMessage } from "../context/MessageContext";

import ReactMarkdown from "react-markdown";



interface MessageProps {
  message: IMessage,
}
export default function Message( { message }: MessageProps ) {

  const { highlightedMessageId } = useMessage();

  const hasMarkdown = /[*_~`\[\]>#-]/.test( message.messageText );

  return (
    <div className="chat-message">
      <div className="chat-message_profile">
        <img src={message.profilePicSource} className="chat-message_profile_image" />
      </div>

      <div className="chat-message_content">
        <span className="chat-message_profile_name"><b>{message.profileName}</b></span>

        <p className={`chat-message_text ${message.id === highlightedMessageId ? "highlight" : ""}`}>
          {hasMarkdown ? 
              <ReactMarkdown>{message.messageText}</ReactMarkdown>
            : 
              message.messageText
          }
        </p>

        { message.attachedFileName ? 
            <div className="file-attachment-info">{message.attachedFileName} was uploaded with this message</div>
          :
            <></>
        }
      </div>
    </div>
  );
}


