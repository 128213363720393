

import AppStateVisual from './components/AppStateVisual';

import Header from './components/Header';

import ChatHead from './components/ChatHead';
import AppBody from './components/AppBody';

import Footer from './components/Footer';

import { SessionContextProvider } from './context/SessionContext';
import { NotificationContextProvider } from './context/NotificationContext';
import { ProfileContextProvider } from './context/ProfileContext';




function App() {

  return (
    <div className="">
      <NotificationContextProvider>
        <SessionContextProvider>
          <ProfileContextProvider>
            <AppStateVisual />

            <Header />

            <ChatHead />
            
            <AppBody />

            <Footer />
          </ProfileContextProvider>
        </SessionContextProvider>
      </NotificationContextProvider>
    </div>
  );
}

export default App;
