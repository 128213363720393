

//import "../css/styles.css";

import { useState, useEffect, useContext, createContext, useCallback } from "react";

import { useSession, useSessionUpdate } from "../context/SessionContext";
import { useNotificationUpdate } from "./NotificationContext";

import { useProfileUpdate } from "./ProfileContext";

import { fetchModelResponse, fetchAssistantResponse } from "../services/fetch";


import ReportsService from "../services/reports";

import { QUESTIONS_PAUSED_NOTIFICATION, QUESTION_LIMIT_MESSAGE, SERVER_ERROR_TEXT, SERVER_ORIGIN, SERVER_WEBSOCKETS_ORIGIN, USE_ASSISTANTS } from "../common/constants";
import { MessageType, PromptType } from "../types/enums";






export {
  PromptContextProvider,
  usePrompt, usePromptUpdate
};


const PromptContext = createContext<any>( null ); // TODO: Properly fill out

const PromptUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function usePrompt() {
  return useContext( PromptContext );
}

function usePromptUpdate() {
  return useContext( PromptUpdateContext );
}


function PromptContextProvider( {children}: ComponentProps ) {

  const { questionsAllowed } = useSession();
  
  const { setSlideReadyToMoveOn, getCurrentSlideNumber, getSessionTranscript, getSessionPresentationContext, getDefaultAppStateHandlerConfig } = useSessionUpdate();

  const { postNotification } = useNotificationUpdate();

  const { getHumanStudentName } = useProfileUpdate();



  // ============================= PROMPT HANDLING =============================
  const handleQuestion = useCallback(async ( question: string, senderName: string, selectedFile?: File ) => { // TODO: Assumes it's a question for now...

    if( !questionsAllowed ) {
      postNotification( QUESTIONS_PAUSED_NOTIFICATION );
      return;
    }

    //setSlideFakeStudentShouldAsk( false );
    //setSlideReadyToMoveOn( false ); // "speak" does this too, BUT, that waits on Server's Response for Model -- May take too long

    let context = getStudentPromptContextPreliminary( getCurrentSlideNumber() );
    context += ` \n${getPresentationContextPrompt( getSessionPresentationContext() )}`;
    context += ` \n${getTranscriptContextPrompt( getSessionTranscript() )}`;
    context += ` \n${getSenderNamePrompt( senderName )}`;
    context += ` \n${getStudentPromptTypePrompt()}`;
    const prompt = question;


    let message: string;
    if( USE_ASSISTANTS ) {
      const assistantResponseOptions = { context, prompt, file: selectedFile };
      message = await getAssistantResponse( assistantResponseOptions );
    }
    else {
      message = await getModelResponse( context, prompt );
    }

    return message;
  }, [questionsAllowed, postNotification]);

  const handleGeneralPrompt = useCallback(async ( prompt: string ) => {

    if( !prompt ) return;

    let context = getGeneralPromptContextPreliminary();
    context += ` \n${getPresentationContextPrompt( getSessionPresentationContext() )}`;
    context += ` \n${getTranscriptContextPrompt( getSessionTranscript() )}`;
    context += ` \n${getStudentNamePrompt( getHumanStudentName() )}`;
    context += ` \n${getResponseSantizerPrompt()}`;

    let message: string;
    if( USE_ASSISTANTS ) {
      const assistantResponseOptions = { context, prompt, messageType: MessageType.CHAT };
      message = await getAssistantResponse( assistantResponseOptions );
    }
    else {
      message = await getModelResponse( context, prompt, PromptType.GENERAL );
    }

    return message;
    //return "";
  }, []);

  const getModelResponse = useCallback(async ( context: string, prompt: string, promptType: string = PromptType.QUESTION_ANSWER ) => {

    try {
      const modelResponse = await fetchModelResponse( promptType, context, prompt, getDefaultAppStateHandlerConfig() );

      if( !modelResponse ) {
        return SERVER_ERROR_TEXT;
      }

      return modelResponse;
    }
    catch( error ) {
      console.error( "Error:", error );
    }
  }, []);

  const getAssistantResponse = useCallback(async ({ context, prompt, file, promptType = PromptType.QUESTION_ANSWER, messageType = MessageType.CHAT }: any) => {

    try {
      const assistantResponse = await fetchAssistantResponse( promptType, context, prompt, file, getDefaultAppStateHandlerConfig() );

      if( !assistantResponse ) {
        return SERVER_ERROR_TEXT;
      }

      return assistantResponse;
    }
    catch (error) {
      console.error('Error:', error);
    }
  }, []);

  // =============================================================================



  return (
    /* TODO: PromptContext passes FUNCTIONS down that may be better in PromptUpdateContext, as that's the pattern I use in other Contexts - i.e. if a method that is called, it is passed thorugh the Update Context */
    <PromptContext.Provider value={ {} } >
      <PromptUpdateContext.Provider value={ {handleQuestion, handleGeneralPrompt} } >
        {children}
      </PromptUpdateContext.Provider>
    </PromptContext.Provider>
  );

}



function condenseTranscript( currentTranscript: string ) {

  const TRANSCRIPT_CHARACTER_LIMIT = 9000;
  if( currentTranscript.length > TRANSCRIPT_CHARACTER_LIMIT ) {
    let startIndex = currentTranscript.length - TRANSCRIPT_CHARACTER_LIMIT;

    return currentTranscript.slice( startIndex );
  }

  return currentTranscript;
}

function condensePresentationContext( currentPresentationContext: string ) {

  const PRESENTATION_CONTEXT_CHARACTER_LIMIT = 5000;
  if( currentPresentationContext.length > PRESENTATION_CONTEXT_CHARACTER_LIMIT ) {
    let startIndex = currentPresentationContext.length - PRESENTATION_CONTEXT_CHARACTER_LIMIT;

    return currentPresentationContext.slice( startIndex );
  }

  return currentPresentationContext;
}



function getStudentPromptContextPreliminary( currentSlideNumber: number ) {
  const context = `Always address the student by their name, you are required to say the student's name in your response.\nCurrent Slide Number: ${currentSlideNumber}`;
  return context;
}

function getGeneralPromptContextPreliminary() {
  const context = "Do the given command. Respond in a way that makes sense for a lecture setting. Respond in plain text without any special formatting. Do not include any mention of the command I am giving, simply act on the command itself without needing to acknowledge it.";
  return context;
}




function getPresentationContextPrompt( presentationContext: string ) {
  const condensedPresentationContext = condensePresentationContext( presentationContext );

  let context = `Here is all of the slide context for the presentation, including the current slide. It may have been condensed to save space. It is meant to give you an idea what each slide is about. If there's context entries, each has the format '[[ {SLIDE NUMBER CAME FROM}: {CONTEXT} ]]'.`;
  context += ` \nPresentation Context: ${condensedPresentationContext}`;
  return context;
}

function getSenderNamePrompt( senderName: string ) {
  const context = `The sender's name is ${senderName}, it is required that you always mention the sender's name in your response.`;
  return context;
}

function getStudentNamePrompt( studentName: string ) {
  const context = `The student's name is ${studentName}, it is required that you always mention the student's name in your response.`;

  //const context = `Always address the student by their name, you are required to say the student's name in your response.\nThe student's name is ${studentName}, it is required that you always mention the student's name in your response.`;

  return context;
}

function getTranscriptContextPrompt( transcript: string ) {
  const condensedTranscript: string = condenseTranscript( transcript );

  let context = `Here's the transcript of the entire session, in order from first to last. Use this only as reference to give more context to the prompt you're receiving. It may have been condensed to save space. If there's transcript entires, each has the format '[[ {SLIDE NUMBER CAME FROM}|{AUTHOR}: {TEXT} ]]'.`;
  context += ` \nTranscript: ${condensedTranscript}`;
  return context;
}


function getStudentPromptTypePrompt() {
  const context = "Determine if the given prompt is a question or a statement, and respond accordingly. Do not repeat yourself. Keep the length of the response to a minimum, especially if the prompt is a statement.";
  return context;
}

function getResponseSantizerPrompt() {
  const context = "Do not include any format of the transcript, such as [[]] or other syntax used for the transcript. Only give a response to the given command, nothing else. Plain text. Just carry out the command, do not include any mention of the command or the context.";
  return context;
}



