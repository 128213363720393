

import "../css/styles.css";


export default function Footer() {

  return (
    <div className="container-footer">
    </div>
  );
}
