
import "../css/styles.css";


import { useState, useEffect, useContext, createContext, useCallback } from "react";


import { FAKE_STUDENT_NAME, FAKE_STUDENT_PROFILE_PIC_SRC, INSTRUCTOR_NAME, INSTRUCTOR_PROFILE_PIC_SRC, TEST_STUDENT_NAME, TEST_STUDENT_PROFILE_PIC_SRC } from "../common/constants";

import { useSession } from "./SessionContext";


export {
  ProfileContextProvider,
  useProfile, useProfileUpdate
};


const ProfileContext = createContext<any>( null ); // TODO: Properly fill out

const ProfileUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function useProfile() {
  return useContext( ProfileContext );
}

function useProfileUpdate() {
  return useContext( ProfileUpdateContext );
}



function ProfileContextProvider( {children}: ComponentProps ) {


  const { studentInfo } = useSession();



  const getHumanStudentName = useCallback( () => {
    if( !studentInfo ) return "Student";

    const studentName = studentInfo.name;

    return studentName;
  }, [studentInfo]);



  const getProfilePicSource = ( senderName = INSTRUCTOR_NAME ) => {

    let profilePicSrc = null;

    switch( senderName ) {
      case INSTRUCTOR_NAME:
        profilePicSrc = INSTRUCTOR_PROFILE_PIC_SRC;
        break;

      case FAKE_STUDENT_NAME:
        profilePicSrc = FAKE_STUDENT_PROFILE_PIC_SRC;
        break;

      case TEST_STUDENT_NAME:
        profilePicSrc = TEST_STUDENT_PROFILE_PIC_SRC;
        break;

      default:
        profilePicSrc = FAKE_STUDENT_PROFILE_PIC_SRC; // TODO: Temp Fallback
        break;
    }

    return profilePicSrc;
  }



  return (
    <ProfileContext.Provider value={ {} } >
      <ProfileUpdateContext.Provider value={ {getHumanStudentName, getProfilePicSource} } >
        {children}
      </ProfileUpdateContext.Provider>
    </ProfileContext.Provider>
  );

}





