
import "../css/styles.css";

import "../css/AppStateVisual.css";


import { AppState } from "../types/enums";


import { useSessionUpdate } from "../context/SessionContext";

import AtomSpinner from "../common/AtomSpinner";




export default function AppStateVisual() {

  const { getAppState } = useSessionUpdate();


  return (
    <>
      {renderAppStateVisual( getAppState() )}
    </>
  );
}


function HealthyState() {
  return <div>Healthy...</div>;
}

function ReadyState() {
  return <div>Ready...</div>;
}

function LoadingState() {
  return <div>Loading...</div>;
}


function ErrorState() {
  return <div>Error...</div>;
}


function RetryingState() {
  console.log("RENDERING RETRYING STATE");


  return <AtomSpinner />

  /*
  return (
    <div className="retrying-overlay">
      <div className="atom-spinner">
        {[...Array(12)].map((_, index) => (
          <div key={index}></div>
        ))}
      </div>
      <div className="retrying-message">Retrying...</div>
    </div>
  );
  */
}





function renderAppStateVisual( appState: AppState ) {

  switch( appState ) {
    case AppState.HEALTHY:
      break; // TODO: Not Implemented right now.
      return <HealthyState />;
    case AppState.READY:
      break; // TODO: Not Implemented right now.
      return <ReadyState />;
    case AppState.ERROR:
      break; // TODO: Not Implemented right now.
      return <ErrorState />;

    case AppState.LOADING:
      break; // TODO: Not Implemented right now.
      return <LoadingState />;
    case AppState.RETRYING:
      return <RetryingState />;
    default:
      return null;
  }

}



