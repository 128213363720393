
import { useState, useEffect, useContext, createContext, useCallback, useRef } from "react";

import { ProfileContextProvider } from "./ProfileContext";

import { useNotificationUpdate } from "./NotificationContext";

import { DEFAULT_MAX_SLIDES, DEFAULT_WELCOME_IMAGE_URL, NOTIFICATION_SPAWN_DELAY, QUESTIONS_PAUSED_NOTIFICATION, SERVER_ORIGIN } from "../common/constants";
import { AppMode, AppState } from "../types/enums";

import ReportsService from "../services/reports";
import { fetchLectureInfo } from "../services/fetch";


export {
  SessionContextProvider,
  useSession, useSessionUpdate,
};


const SessionContext = createContext<any>( null ); // TODO: Properly fill out

const SessionUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function useSession() {
  return useContext( SessionContext );
}

function useSessionUpdate() {
  return useContext( SessionUpdateContext );
}



const DELIMITER_OPEN = "[[";
const DELIMITER_CLOSE = "]], ";





function SessionContextProvider( {children}: ComponentProps ) {
  // Provider handles...

  // ... Creating State

  // TODO: NEW STATE AppMode... consider conditionally creating other State, since things like welcomeSlideURL won't apply and would have meaningless state taking up memory... (?)
  // ... TODO: Consider having separate AppContext Component ? So can have AppMode Enum there?? ... Or just put in an Enums file...?
  const [ appMode, setAppMode ] = useState<AppMode>( AppMode.PRESENTATION );

  const [ slideCount, setSlideCount ] = useState<number>( DEFAULT_MAX_SLIDES ); // Retrieved from Server during setup. Default, in case that fails
  const [ welcomeSlideURL, setWelcomeSlideURL ] = useState<string>( DEFAULT_WELCOME_IMAGE_URL );
  const [ studentInfo, setStudentInfo ] = useState<any>( ); // { name: string, email: string }
  const [ presentationInfo, setPresentationInfo ] = useState<any>( ); // { subject: string, lessonId: string }
  
  //const [ appState, setAppState ] = useState<AppState>( AppState.LOADING );
  //const [ appState, setAppState ] = useState<AppState>( AppState.HEALTHY );
  const appStateRef = useRef<AppState>( AppState.HEALTHY );

  const slideContextRef = useRef<string>( "" );
  const sessionPresentationContextRef = useRef<string>( `` );
  const sessionTranscriptRef = useRef<string>( `` );



  // TODO: IMPLEMENT THESE ELSEWHERE...
  //const [ slideFakeStudentQuestion, setSlideFakeStudentQuestion ] = useState<any>( );
  //const [ slideFakeStudentShouldAsk, setSlideFakeStudentShouldAsk ] = useState<any>( ); // Fake Student should always ask Question (if enabled) UNLESS (e.g.) another Student has already
  //const [ slideInstructorFollowUpText, setSlideInstructorFollowUpText ] = useState<any>( );
  //const [ slideFakeStudentFollowUpText, setSlideFakeStudentFollowUpText ] = useState<any>( );
  //const [ slideInstructorFollowUpDelay, setSlideInstructorFollowUpDelay ] = useState<any>( );
  //const [ slideFakeStudentFollowUpDelay, setSlideFakeStudentFollowUpDelay ] = useState<any>( );


  // ======== CONFIG ========
  const [ questionsAllowed, setQuestionsAllowed ] = useState<boolean>( true ); // Whether or not Q&A is enabled (or allowed for where at in Presentation)

  // ========================


  const [ speechRecognition, setSpeechRecognition ] = useState<any>( );

  const [ sessionPaused, setSessionPaused ] = useState<boolean>( true );
  const sessionPausedRef = useRef( sessionPaused ); // NOTE: Ref is used to ensure that we can access latest state in same Render Cycle

  const slideNumberRef = useRef<number>( 0 );

  const [ someoneSpeaking, setSomeoneSpeaking ] = useState<boolean>( false );


  const [ isMuted, setIsMuted ] = useState<boolean>( false );



  const { postNotification } = useNotificationUpdate();



  useEffect( () => {
    try {
      setupLecture();

      //cancelSpeechSynthesis();

      ReportsService.reportLaunch();
    }
    catch (exception) {
      ReportsService.reportLaunch( true );
    }
  }, []);


  // ... Updating State

  // NOTE: All updates to sessionPaused should be done through these functions, to ensure sessionPausedRef is updated as well
  const updateSessionPaused = ( newPausedState: boolean ) => {
    setSessionPaused( newPausedState );
    sessionPausedRef.current = newPausedState;
  }
  const getSessionPaused = () => {
    return sessionPausedRef.current;
  }


  const getSessionTranscript = () => {
    return sessionTranscriptRef.current;
  }

  const addTextToTranscript = ( text: string, author: string ) => {

    /*
    setTranscript( (previousTranscript: string) => {
      return previousTranscript += `${DELIMITER_OPEN}${slideNumberRef.current}|${author}: ${text}${DELIMITER_CLOSE}`;
    });
    */
    sessionTranscriptRef.current += `${DELIMITER_OPEN}${slideNumberRef.current}|${author}: ${text}${DELIMITER_CLOSE}`;
  }


  const muteStudents = () => {
    setIsMuted( true );

    //stopSpeechRecognition(); // TODO: Being done in ChatContainer ;( due to Context hierarchy (for now...)
    return true;
  }

  const unmuteStudents = () => {

    if( !questionsAllowed ) { // Tested first since most fundamental (e.g. if they can't ask questions on this slide at all, that is more prominent than not being able to ask because someone is speaking)
      postNotification( "You can't unmute yet. " + QUESTIONS_PAUSED_NOTIFICATION );
      return false;
    }
    else if( someoneSpeaking ) {
      postNotification( "You're automatically muted while someone else is speaking" );
      return false;
    }

    setIsMuted( false );

    //recognizeSpeech(); // TODO: Being done in ChatContainer ;( due to Context hierarchy (for now...)
    return true;
  }


  const getCurrentSlideNumber = useCallback( () => {
    return slideNumberRef.current;
  }, []);

  const updateCurrentSlideNumber = ( newSlideNumber: number ) => {
    slideNumberRef.current = newSlideNumber;
  }


  const getSlideContext = () => {
    return slideContextRef.current;
  }

  const updateSlideContext = ( newContext: string ) => {
    slideContextRef.current = newContext;
  }

  const getSessionPresentationContext = () => {
    return sessionPresentationContextRef.current;
  }

  const updateSessionPresentationContext = ( newContext: string ) => {
    /*
    setSessionPresentationContext( (previousContext: string) => {
      return previousContext += `${DELIMITER_OPEN}${slideNumberRef.current}: ${newContext}${DELIMITER_CLOSE}`;
    });
    */
    sessionPresentationContextRef.current += `${DELIMITER_OPEN}${slideNumberRef.current}: ${newContext}${DELIMITER_CLOSE}`;
  }


  const getAppState = () => {
    return appStateRef.current;
  }

  const updateAppState = ( newState: AppState ) => {
    appStateRef.current = newState;
  }


  const getDefaultAppStateHandlerConfig = () => {
    return {
      onRetry: () => {
        //console.log( "AppStateHandler onRetry" );
        appStateRef.current = AppState.RETRYING;
      },
      onSuccess: () => {
        //console.log( "AppStateHandler onSuccess" );
        appStateRef.current = AppState.HEALTHY;
      },
      onFailure: () => {
        //console.log( "AppStateHandler onFailure" );
        appStateRef.current = AppState.ERROR;
      }
    };
  }







  const getLectureInfo = useCallback( async () => {
    const lectureInfo = await fetchLectureInfo( getDefaultAppStateHandlerConfig() );

    if( !lectureInfo ) {
      throw new Error( "Failed to fetch lecture info" );
    }

    return lectureInfo;
  }, []);

  const setupLecture = useCallback( async () => {


    const lectureInfo = await getLectureInfo(); // TODO: Type lectureInfo

    if( lectureInfo.slideCount ) setSlideCount( lectureInfo.slideCount );
    if( lectureInfo.welcomeSlideImageURL ) setWelcomeSlideURL( lectureInfo.welcomeSlideImageURL );
    setStudentInfo( lectureInfo.studentInfo );

    setPresentationInfo( lectureInfo.presentationInfo );



    muteStudents();
    postNotification("You're automatically muted when joining");

    setTimeout(() => { // TODO: Really should have some Notification Queue system...
      postNotification("Press the Play button whenever you're ready to start!");

      /*
      setTimeout(() => {
        postNotification("At any time, click pause to halt slide changes");
      }, NOTIFICATION_SPAWN_DELAY);
      */
    }, NOTIFICATION_SPAWN_DELAY);
  }, []);






  // ... Persisting State down into Children
  return (
    <SessionContext.Provider value={ { appMode, slideCount, welcomeSlideURL, studentInfo, presentationInfo,
                                       questionsAllowed, speechRecognition, speechSynthesis, 
                                       sessionPaused,
                                       isMuted, someoneSpeaking} } >
      <SessionUpdateContext.Provider value={ {setQuestionsAllowed, setSpeechRecognition, 
                                              getSessionTranscript, addTextToTranscript, 
                                              getSlideContext, updateSlideContext,
                                              getSessionPresentationContext, updateSessionPresentationContext,
                                              setIsMuted, muteStudents, unmuteStudents, 
                                              getCurrentSlideNumber, updateCurrentSlideNumber,
                                              updateSessionPaused, getSessionPaused, setSomeoneSpeaking,
                                              getAppState, updateAppState, getDefaultAppStateHandlerConfig } } >

        {children}

      </SessionUpdateContext.Provider>
    </SessionContext.Provider>
  )
}











/* */
